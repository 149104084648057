<template>
  <div>
    <h4 class="title" v-if="oneOfTwo">Create Property (1/2)</h4>
    <h4 class="title" v-if="!oneOfTwo">Create Property (2/2)</h4>
    <b-row class="mt-3" v-if="oneOfTwo">
      <b-col cols="12" md="12">
        <rm-input
          outlined
          type="text"
          label="Name Of Property"
          v-model="form.name"
          @blur="$v.form.name.$touch()"
        >
        </rm-input>
        <template v-if="$v.form.name.$error">
          <p v-if="!$v.form.name.required" class="error form-error">
            Property Name is required
          </p></template
        >
      </b-col>

      <b-col cols="12" md="12">
        <label class="label text-dark text-sm" for="state-dropdown"
          >Location</label
        >
        <div class="select-box">
          <select
            id="state-dropdown"
            class="my-select"
            required
            v-model="form.location_id"
            @blur="$v.form.location_id.$touch()"
          >
            <option selected disabled class="text-gray-100"></option>
            <option
              class="py-0 mt-0 mb-0"
              v-for="option in GetLocationList"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <b-icon icon="caret-down-fill"></b-icon>
        </div>
        <p
          v-if="$v.form.location_id.$anyError"
          class="error form-error"
          style="margin-top: 1rem"
        >
          Location is required
        </p>
      </b-col>

      <b-col cols="12" class="mt-5">
        <rm-text-area
          outlined
          v-model="form.description"
          :rows="5"
          type="text"
          label="Description"
        >
        </rm-text-area>
      </b-col>

      <b-col cols="12" class="mb-3">
        <label class="label text-dark text-sm" for="state-dropdown">Type</label>
        <div class="select-box">
          <select
            v-model="form.product_type_id"
            id="state-dropdown"
            class="my-select"
            required
            @blur="$v.form.product_type_id.$touch()"
            @change="checkHomeWorth(form.product_type_id)"
          >
            <option selected disabled class="text-gray-100"></option>
            <option
              class="py-0 mt-0 mb-0"
              v-for="option in typeOptions"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </select>
          <b-icon icon="caret-down-fill"></b-icon>
        </div>
        <p
          v-if="$v.form.product_type_id.$anyError"
          class="error form-error"
          style="margin-top: 1rem"
        >
          Type is required
        </p>
      </b-col>

      <b-col cols="12" md="6">
        <rm-input
          v-model="form.unit_price"
          outlined
          type="text"
          label="Unit Price"
          required
          @blur="$v.form.unit_price.$touch()"
        >
        </rm-input>
        <template v-if="$v.form.unit_price.$error">
          <p v-if="!$v.form.unit_price.required" class="error form-error">
            Unit Price is required
          </p>
          <p v-if="!$v.form.unit_price.integer" class="error form-error">
            Input must be an integer
          </p>
        </template>
      </b-col>

      <b-col cols="12" md="6">
        <rm-input
          v-model="form.units_available"
          outlined
          type="text"
          label="Units Available"
          @blur="$v.form.units_available.$touch()"
        >
        </rm-input>
        <template v-if="$v.form.units_available.$error">
          <p v-if="!$v.form.units_available.required" class="error form-error">
            Units Available is required
          </p>
          <p v-if="!$v.form.units_available.integer" class="error form-error">
            Input must be an integer
          </p>
        </template>
      </b-col>

      <b-col cols="12" md="6" v-if="paymentPlanNeeded">
        <rm-input
          v-model="form.size_per_unit"
          outlined
          type="text"
          label="Size per Unit"
          @blur="$v.form.size_per_unit.$touch()"
        >
        </rm-input>
        <!-- <template v-if="$v.form.size_per_unit.$error">
          <p v-if="!$v.form.size_per_unit.required" class="error form-error">
            Size per unit is required
          </p>
          <p v-if="!$v.form.size_per_unit.integer" class="error form-error">
            Input must be an integer
          </p>
        </template> -->
      </b-col>

      <b-col cols="12" md="6" class="mb-3">
        <label class="label text-dark text-sm" for="state-dropdown"
          >Unit of Measurement</label
        >
        <div class="select-box">
          <select
            id="state-dropdown"
            class="my-select"
            required
            v-model="form.unit_of_measurement"
            @blur="$v.form.unit_of_measurement.$touch()"
          >
            <!-- <option selected disabled class="text-gray-100"></option> -->
            <option
              class="py-0 mt-0 mb-0"
              v-for="(option, index) in unitOfMeasurementOptions"
              :value="option"
              :key="index"
            >
              {{ option }}
            </option>
          </select>
          <b-icon icon="caret-down-fill"></b-icon>
        </div>
        <p
          v-if="$v.form.unit_of_measurement.$anyError"
          class="error form-error"
          style="margin-top: 1rem"
        >
          Unit is required
        </p>
      </b-col>
    </b-row>

    <template v-if="paymentPlanNeeded">
      <b-row class="mt-3" v-if="oneOfTwo">
        <b-col cols="12">
          <p class="row__heading">Payment Plan</p>
        </b-col>

        <b-col cols="12" md="6" v-if="paymentPlanNeeded">
          <rm-input
            v-model="form.minimum_duration"
            outlined
            type="text"
            label="Minimum duration"
            required
            placeholder="6 months"
            @blur="$v.form.minimum_duration.$touch()"
          >
          </rm-input>
          <!-- <template v-if="$v.form.minimum_duration.$error">
            <p v-if="!$v.form.minimum_duration.required" class="error form-error">
              Minimum duration is required
            </p>
            <p v-if="!$v.form.minimum_duration.integer" class="error form-error">
              Input must be an integer
            </p>
          </template> -->
        </b-col>

        <b-col cols="12" md="6" v-if="paymentPlanNeeded">
          <rm-input
            v-model="form.maximum_duration"
            outlined
            type="text"
            label="Maximum duration"
            required
            placeholder="36 months"
            @blur="$v.form.maximum_duration.$touch()"
          >
          </rm-input>
          <!-- <template v-if="$v.form.maximum_duration.$error">
            <p v-if="!$v.form.maximum_duration.required" class="error form-error">
              Maximum duration is required
            </p>
            <p v-if="!$v.form.maximum_duration.integer" class="error form-error">
              Input must be an integer
            </p>
          </template> -->
        </b-col>

        <b-col cols="12" md="6" v-if="paymentPlanNeeded">
          <rm-input
            v-model="form.min_dep_percentage"
            outlined
            type="number"
            label="Minimum Deposit Percentage"
            required
            placeholder="3 %"
            @blur="$v.form.min_dep_percentage.$touch()"
          >
          </rm-input>
          <!-- <template v-if="$v.form.min_dep_percentage.$error">
            <p
              v-if="!$v.form.min_dep_percentage.required"
              class="error form-error"
            >
              Minimum deposit percentage is required
            </p>
          </template> -->
        </b-col>

        <b-col cols="12" md="6" v-if="paymentPlanNeeded">
          <rm-input
            v-model="form.price_inc_percentage"
            outlined
            type="number"
            label="Price Increase Percentage"
            required
            placeholder="33 %"
            @blur="$v.form.price_inc_percentage.$touch()"
          >
          </rm-input>
          <!-- <template v-if="$v.form.price_inc_percentage.$error">
            <p
              v-if="!$v.form.price_inc_percentage.required"
              class="error form-error"
            >
              Price Increase Percentage is required
            </p>
          </template> -->
        </b-col>
      </b-row>
    </template>

    <b-row class="mt-5" v-if="!oneOfTwo">
      <b-col cols="12">
        <label for="tags-basic">Features</label>
        <b-form-tags
          tag-variant="secondary"
          placeholder="Add feature"
          addButtonText="Add"
          input-id="tags-basic"
          v-model="form.features"
          @blur="$v.form.features.$touch()"
        ></b-form-tags>
        <!-- <p class="mt-2">Value: {{ value }}</p> -->
      </b-col>
      <b-col cols="12" class="mt-3">
        <template v-if="$v.form.features.$error">
          <p v-if="!$v.form.features.required" class="error form-error">
            Features is required
          </p></template
        >
      </b-col>

      <b-col cols="12" class="mt-5">
        <rm-upload
          @completed="setBannerUrl"
          v-model="form.images"
          @blur="$v.form.images.$touch()"
        />
        <template v-if="$v.form.images.$error">
          <p v-if="!$v.form.images.required" class="error form-error">
            Image upload is required
          </p>
        </template>
      </b-col>
    </b-row>

    <b-row align-h="end" class="mt-lg-5 mt-0 pt-lg-5 pt-0">
      <b-col cols="2" class="mb-4" v-if="oneOfTwo">
        <rm-base-button
          @click="close"
          v-if="!isLoading"
          class="ml-auto"
          text="Cancel"
          bgColor="grey"
          textColor="black"
        ></rm-base-button>
      </b-col>
      <b-col cols="2" class="mb-4" v-if="!oneOfTwo">
        <rm-base-button
          @click="oneOfTwo = true"
          v-if="!isLoading"
          class="ml-auto"
          text="Previous"
          bgColor="grey"
          textColor="black"
        ></rm-base-button>
      </b-col>
      <b-col cols="2" class="mb-4" v-if="oneOfTwo">
        <rm-base-button
          :loadingButton="isLoading"
          @click="oneOfTwo = false"
          class="ml-auto"
          text="Next"
          bgColor="primary"
          textColor="white"
        ></rm-base-button>
      </b-col>
      <b-col cols="2" class="mb-4" v-if="!oneOfTwo">
        <rm-base-button
          :loadingButton="isLoading"
          @click="createProperty"
          class="ml-auto"
          text="Create"
          bgColor="primary"
          textColor="white"
        ></rm-base-button>
      </b-col>
    </b-row>
    <!-- <p v-if="$v.$anyError" class="error big-center-text">
      Please fill out required field(s)
    </p> -->
  </div>
</template>

<script>
import { required, integer } from 'vuelidate/lib/validators';

import RmTextArea from '../../../components/global/RmTextArea.vue';
// import countries from '@/utils/countries.json';
import states from '@/utils/states.json';
import { mapGetters} from "vuex";
import lgas from '@/utils/lgas.json';
import RmUpload from './RmUpload.vue';

export default {
  components: { RmTextArea, RmUpload },
  data() {
    return {
      value: [],
      oneOfTwo: true,
      isLoading: false,
      paymentPlanNeeded: true,
      locationList: [],
      states,
      typeOptions: ['Land', 'House'],
      unitOfMeasurementOptions: ['sqm'],

      form: {
        name: null,
        description: null,
        location_id: null,
        product_type_id: null,
        total_units: null,
        unit_price: null,
        units_available: null,
        size_per_unit: null,
        unit_of_measurement: null,
        minimum_duration: null,
        maximum_duration: null,
        min_dep_percentage: null,
        price_inc_percentage: null,
        images: null,
        features: this.value,
      },
    };
  },

  validations: {
    form: {
      name: { required },
      location_id: { required },
      product_type_id: { required },
      // total_units: { required },
      unit_price: { required, integer },
      units_available: { required, integer },
      size_per_unit: { required, integer },
      unit_of_measurement: { required },
      // minimum_duration: { required, integer },
      // maximum_duration: { required, integer },
      // min_dep_percentage: { required },
      // price_inc_percentage: { required },
      images: { required },
      features: { required },
    },
  },
  computed: {
    ...mapGetters({
      GetLocationList: 'locations/locationsList'
    })
    // states() {
    //   return countries.find((country) => country.name == this.form.country)
    //     ?.states;
    // },
  },
  // watch: {
  //   'form.state'(value) {
  //     const selectedState = states.find((state) => state.name == value);
  //     const stateId = selectedState ? selectedState.id : null;
  //     this.lgaOptions = lgas.filter((lga) => lga.state_id == stateId);
  //   },
  // },
  methods: {
    setBannerUrl(url) {
      this.form.images = url;
      // console.log(url);
    },
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));

      this.$bvModal.hide('modal-lg-new-property');
    },
    checkHomeWorth(event){
      // toggles the payment plan option
      if(event === '5e04711f-8563-4463-b64e-5743ba6c201e'){
        this.paymentPlanNeeded = false;
        this.form.size_per_unit = 1;
      } else {
        this.paymentPlanNeeded = true;
      }
    },
    async createProperty() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.dispatch('toast', {
          variant: 'error',
          message: 'Please fill all required fields',
        });
        // this.close();
        return;
      }

      const loader = this.$loading.show();
      try {
        const body = {
          name: this.form.name,
          location_id: this.form.location_id,
          description: this.form.description,
          product_type_id: this.form.product_type_id,
          unit_price: Number(this.form.unit_price) * 100,
          units_available: Number(this.form.units_available),
          size_per_unit: Number(this.form.size_per_unit),
          unit_of_measurement: this.form.unit_of_measurement,
          total_units: Number(this.form.units_available),
          payment_plan: {
            min_duration: Number(this.form.minimum_duration),
            max_duration: Number(this.form.maximum_duration),
            minimum_deposit_percentage: Number(this.form.min_dep_percentage),
            price_percentage: Number(this.form.price_inc_percentage),
          },
          images: this.form.images,
          features: this.form.features,
        };

        await this.$store.dispatch('locations/createProperty', body);
        loader.hide();
        this.$emit('completed');
        this.close();
      } catch (error) {
        loader.hide();
        console.log(error);
      }
    },
    async fetchLocationList() {
      try {
        await this.$store.dispatch(
          'locations/fetchLocationsList'
        );

        // loader.hide();
        // this.locationList = data.payload;
        // this.totalRows = results.paging.total;
      } catch (error) {
        // loader.hide();
        console.log(error);
      }
    },
    

    async fetchProductTypes() {
      try {
        let { data } = await this.$store.dispatch(
          'locations/fetchProductTypes'
        );

        // loader.hide();
        this.typeOptions = data.payload;
        // this.totalRows = results.paging.total;
      } catch (error) {
        // loader.hide();
        console.log(error);
      }
    },
  },

  async created() {
    this.fetchLocationList();
    this.fetchProductTypes();
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
}
.select-box {
  position: relative;
  display: flex;
  // height: 41%;
  border: 1px solid rgba(112, 112, 112, 0.432);
  border-radius: 0.25em;

  // .select-box select {
  //   border: 0;
  //   width: 100%;
  //   outline: 0;
  // }

  svg {
    position: absolute;
    right: 5%;
    bottom: 25%;
  }

  label {
    margin-bottom: 0.25rem;
  }

  .my-select {
    appearance: none;
    height: 100%;
    width: 100%;
    display: grid;
    // min-width: 15ch;
    // max-width: 30ch;
    border: 1px solid rgba(112, 112, 112, 0.432);
    border-radius: 0.25em;
    border: 0;
    padding: 0.8em 1.25em;
    font-size: 0.9rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  }
}

.form-error {
  margin-top: -1rem;
}

.big-center-text {
  font-size: 1.6rem;
  // font-weight: 600;
  text-align: center;
}

#tags-basic {
  color: white;
  background-color: blue;
}
</style>
