<template>
  <div v-if="canView">
    <div class="properties-add pointer" v-if="canCreate" @click="showModal">
      <!-- <b-icon icon="plus-circle-fill" class="plus-icon"></b-icon> -->
      <rm-base-icon name="add" />
      <h2 class="text-bold">Add Property</h2>
    </div>
    <div class="bg-white p-4 properties-table pt-4 mt-4">
      <b-table-simple>
        <b-thead>
          <b-tr class="greyScale">
            <b-th class="text-justify"> </b-th>
            <b-th class="text-center">PROPERTY</b-th>
            <b-th class="text-center">LOCATION</b-th>
            <b-th class="text-center">UNIT SIZE</b-th>
            <b-th class="text-center">AVAILABLE UNITS</b-th>
            <b-th class="text-center">AMOUNT</b-th>
            <b-th class="text-center">TYPE</b-th>
            <b-th class="text-center">STATUS</b-th>
          </b-tr>
        </b-thead>
        <b-tbody
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          class="text-center"
          responsive
          v-for="(item, index) in items"
          :key="index"
        >
          <b-tr class="colspan">
            <b-td>
              <b-form-checkbox class="text-justify"></b-form-checkbox>
            </b-td>
            <b-td>
              <router-link class="router-link" :to="`/properties/${item.id}`">{{
                item.name
              }}</router-link></b-td
            >
            <b-td>{{ item.location_name }}</b-td>
            <b-td>{{ item.size_per_unit }}{{ item.unit_of_measurement }}</b-td>
            <b-td class="text-center">{{ item.units_available }}</b-td>
            <b-td>{{ `₦${$formatCurrency(item.unit_price / 100)}` }}</b-td>
            <b-td class="text-capitalize">{{ item.product_type }}</b-td>
            <b-td>
              <span class="status_active" v-if="item.status === 'open'">
                Active
              </span>
              <span class="status_inactive" v-if="item.status === 'closed'"
                >Inactive</span
              >
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="pagination-section">
        <h3 class="mt-2">Showing page {{ currentPage }} of {{ totalPages }}</h3>
        <b-pagination
          @change="fetchProperties"
          class="mt-2"
          v-model="currentPage"
          :current-page="currentPage"
          :total-rows="totalRows"
          :per-page="limit"
          first-number
          last-number
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>

    <rm-base-modal id="new-property" size="lg">
      <rm-new-property @completed="fetchProperties" />
    </rm-base-modal>
  </div>
  <div v-else>
    <p>You do not have access to this content</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RmNewProperty from "./components/RmNewProperty.vue";
export default {
  data() {
    return {
      items: [],
      perPage: 5,
      currentPage: 1,
      limit: 5,
      totalRows: 5,
      totalPages: 2,
      // rows: 10,
    };
  },
  components: { RmNewProperty },
  async created() {
    this.fetchProperties();
  },
  methods: {
    showModal() {
      this.$bvModal.show("modal-lg-new-property");
    },
    async fetchProperties(page) {
      const loader = this.$loading.show();
      try {
        let data = await this.$store.dispatch("locations/fetchProperties", {
          page: page ?? 1,
          limit: this.limit,
        });
        this.currentPage = page;
        const { paging, rows } = data.data.payload;
        this.items = rows;
        this.currentPage = paging.currentPageNo;
        this.totalRows = paging.total;
        this.limit = paging.limit;
        this.totalPages = paging.totalNoPages;
        loader.hide();
      } catch (error) {
        loader.hide();
        // console.log(error);
      }
    },
  },
  computed: {
    ...mapState('auth', ['userObj']),
    userRoles() {
      return  this.userObj.role.scopes ?? []
    },
    canView(){
      if (this.userRoles.includes('product.read')){
        return true
      } else {
        return false
      }
    },
    canCreate(){
      if (this.userRoles.includes('product.create')){
        return true
      } else {
        return false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.plus-icon {
  width: 3rem;
  height: 2rem;
  fill: #1d9067;
}
.status_active {
  background-color: #e8fbf4;
  border-radius: 4px;
  color: #1d9067;
  padding: 0.25rem 0.9rem;
}
.status_inactive {
  background-color: #fff5e9;
  color: #f08000;
  border-radius: 4px;
  padding: 0.25rem 0.9rem;
}
.properties-add {
  width: 12.5rem;
  height: 11.2rem;
  box-shadow: 2px 2px 8px rgba(52, 138, 167, 0.2);
  border-radius: 8px;
  background: #e8fbf4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.properties-add h2 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
}
.properties-table {
  border-radius: 8px;
}

.greyScale {
  background-color: #f0f0f0;
}
.table__fonts {
  font-family: "DM Sans", sans-serif;
}
.pagination-section {
  display: flex;
  padding: 1rem 2rem;
  border: solid grey 0.1px;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  margin-top: 13rem;
}
.pagination-section h3 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8125rem;
  // line-height: 1.63rem;
}
.router-link {
  text-decoration: none;
  color: black;
}
</style>
